import { x } from "@xstyled/emotion";
import Footer from "components/ui/Footer";
import InstagramStrip from "components/ui/Instagram/InstaStrip";
import TopBar from "components/ui/TopBar";
import { FC } from "react";

const Page: FC = ({ children }) => {
  return (
    <x.div display="flex" flexDirection="column" minH="100vh">
      <TopBar />

      <x.div flex="1">
        {children}
      </x.div>

      <InstagramStrip />
      {/* <InfoBox /> */}
      <Footer />
    </x.div>
  );
};

export default Page;
