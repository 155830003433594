import { x } from "@xstyled/emotion";
import { SystemProps } from "@xstyled/system";
import { Input, InputProps } from "anolis-ui";
import Label, { LabelProps } from "components/formik/Label";
import { useField } from "formik";
import { FC, Fragment, ReactElement, ReactNode, RefObject, useEffect, useRef } from "react";
import { FormattedMessage } from "react-intl";

import { useIsError } from "./ValidateWithoutTouch";

export interface TextFieldProps extends LabelProps {
  labelText?: string | ReactNode;
  label?: ReactElement;
  _labelText?: SystemProps;
  inputProps?: InputProps;
  required?: boolean;
  textarea?: boolean;
  autofocus?: boolean;
  message?: string;
}

const TextField: FC<TextFieldProps> = ({ ...props }) => {
  const [field, meta] = useField(props.name);
  const isError = useIsError(meta);
  const inputElement = useRef<HTMLDivElement>(null);

  useEffect(() => {
    inputElement?.current?.querySelector("input")?.focus();
  }, []);

  return (
    <Label {...props}>
      <x.div
        fontFamily="avenir"
        fontWeight={600}
        fontSize="0.875rem"
        lineHeight="1.3125"
        mb="0.25rem"
        {...props._labelText}
        {...isError && { color: "robe" }}
      >
        {props.label}
        {props.labelText && (
          <Fragment>
            {props.labelText}{" "}
            {props.required
              ? (
                <x.span color="robe" ml="0.25rem">
                  <FormattedMessage id="components.forms.required" defaultMessage="*" />
                </x.span>
              ) : (
                <x.span ml="0.25rem" fontWeight={400}>
                  {props.message
                    ? props.message
                    : <FormattedMessage id="components.forms.optional" defaultMessage="(optional)" />}
                </x.span>
              )}
          </Fragment>
        )}
      </x.div>

      {props.textarea
        ? (
          <Input
            ref={inputElement as RefObject<HTMLInputElement>}
            {...props.inputProps}
            _textarea={{
              ...field,
              ...props.inputProps?._textarea,
              onChange: e => {
                field.onChange(e);
                props.inputProps?.onChange?.(e);
              }
            }}
            _input={{
              ...field,
              ...props.inputProps?._input,
              onChange: e => {
                field.onChange(e);
                props.inputProps?.onChange?.(e);
              }
            }}
          />
        )
        : (
          <Input
            ref={props.autofocus ? inputElement as RefObject<HTMLInputElement> : null}
            {...props.inputProps}
            _input={{
              ...field,
              ...props.inputProps?._input,
              value: props.inputProps?._input?.value ?? field.value,
              onChange: e => {
                field.onChange(e);
                props.inputProps?.onChange?.(e);
              }
            }}

          />
        )}
    </Label>
  );
};

export default TextField;
