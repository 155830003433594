import styled, { x } from "@xstyled/emotion";
import { FC, memo, useState } from "react";
import RobeHeading from "components/ui/RobeHeading";
import { Container, TextLink, useModal } from "anolis-ui";
import Link from "next/link";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { useRouter } from "next/router";
import NewsletterForm from "components/forms/Newsletter";
import useUserType from "utils/useUserType";

const Footer: FC = () => {
  const userType = useUserType();
  const { formatMessage } = useIntl();
  const links = userType === "b2b" ? [
    { url: "https://www.robe.cz/about-us", label: formatMessage(content.about), target: "_blank" },
    { url: "/static/contacts", label: formatMessage(content.contacts) },
    { url: "/static/privacy", label: formatMessage(content.privacy) }
  ] : [
    // { url: "/static/faq", label: "FAQ" },
    // { url: "/static/delivery-and-returns", label: "Delivery & Returns" },
    // { url: "/static/sizing", label: "Sizing sheets" },
    { url: "https://www.robe.cz/about-us", label: formatMessage(content.about), target: "_blank" },
    { url: "/static/contacts", label: formatMessage(content.contacts) },
    { url: "/static/privacy", label: formatMessage(content.privacy) },
    { url: "/static/terms-and-conditions", label: formatMessage(content.terms), target: "_blank" },
    { url: "/static/terms-and-conditions-cze", label: formatMessage(content.termsCze), target: "_blank" },
    { url: "/static/complaint-policy", label: formatMessage(content.complaint), target: "_blank" },
    { url: "/static/complaint-policy-cze", label: formatMessage(content.complaintCze), target: "_blank" }
  ];

  const socialLinks = [
    { url: "https://www.facebook.com/robelighting/", label: "Facebook", target: "_blank" },
    { url: "https://www.instagram.com/robelighting/", label: "Instagram", target: "_blank" },
    { url: "https://www.linkedin.com/company/robe-lighting-s-r-o-/", label: "LinkedIn", target: "_blank" },
    { url: "https://twitter.com/Robelighting", label: "Twitter", target: "_blank" },
    { url: "https://www.pinterest.com/robelighting/", label: "Pinterest", target: "_blank" }
  ];

  const [clicks, setClicks] = useState(0);
  const router = useRouter();

  if (clicks > 3) {
    setClicks(0);
    router.push("/credits");
  }

  const [openNewsletter] = useModal(NewsletterForm);

  return (
    <FooterStyle
      pt={{ _: "3rem", sm: "4rem" }}
      pb={{ _: "2rem", sm: "3rem" }}
    >
      <Container>
        <x.div display="grid" gap="2rem" gridTemplateColumns={{ _: "repeat(1, 1fr)", lg: "repeat(3, 1fr)" }}>
          <x.div>
            <RobeHeading t="h4" textAlign="left" fontWeight="700" color="#fff">
              <FormattedMessage id="footer.title1" defaultMessage="About" />
            </RobeHeading>

            <x.div
              display="grid"
              gap="1.5rem 4.875rem"
              gridTemplateColumns={{ _: "repeat(1, 1fr)", sm: "repeat(2, min-content)" }}
              gridTemplateRows="auto"
              mt="2rem"
            >
              {links.map((link, k) => (
                <Link href={`${link.url}`} key={k} passHref>
                  <TextLink whiteSpace="nowrap" target={link.target ? link.target : "_self"}>
                    {link.label}
                  </TextLink>
                </Link>
              )
              )}
            </x.div>
          </x.div>

          <x.div>
            <RobeHeading t="h4" textAlign="left" fontWeight="700" color="#fff">
              <FormattedMessage id="footer.title2" defaultMessage="Follow us" />
            </RobeHeading>

            <x.div
              display="grid"
              gap="1.5rem 4.875rem"
              gridTemplateColumns={{ _: "repeat(1, 1fr)", sm: "repeat(2, min-content)" }}
              gridTemplateRows="auto"
              mt="2rem"
            >
              {socialLinks.map((socialLink, k) => (
                <Link href={`${socialLink.url}`} key={k} passHref>
                  <TextLink whiteSpace="nowrap" target={socialLink.target ? socialLink.target : "_self"}>
                    {socialLink.label}
                  </TextLink>
                </Link>
              )
              )}
            </x.div>
          </x.div>

          <x.div>
            <RobeHeading t="h4" textAlign="left" fontWeight="700" color="#fff">
              <FormattedMessage id="footer.title3" defaultMessage="Stay in touch" />
            </RobeHeading>

            <x.div
              display="grid"
              gap="1.5rem 4.875rem"
              gridTemplateColumns={{ _: "repeat(1, 1fr)", sm: "repeat(2, min-content)" }}
              gridTemplateRows="auto"
              mt="2rem"
            >
              <TextLink whiteSpace="nowrap" onClick={() => openNewsletter({})}>
                <FormattedMessage id="footer.newsletter" defaultMessage="Robe international newsletter" />
              </TextLink>
            </x.div>
          </x.div>

          {/* <x.div>
            <RobeHeading t="h4" textAlign="left" fontWeight="700" color="#fff">
              {formatMessage(content.title)} // TODO: use FormattedMessage when uncommented
            </RobeHeading>
          </x.div> */}
        </x.div>

        <x.div
          pt="6rem"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          flexDirection={{ _: "column", sm: "row" }}
        >
          <x.div
            display="flex"
            alignItems="center"
            flexDirection={{ _: "column", sm: "row" }}
          >
            <Link href="https://robe.cz" passHref>
              <TextLink>
                <x.img
                  src="/static/img/robe-white.svg"
                  width="96"
                  height="24"
                  alt=""
                  cursor="pointer"
                  mr={{ sm: "1.5rem" }}
                  mb={{ _: "2rem", sm: 0 }}
                />
              </TextLink>
            </Link>

            <x.p color="#fff" fontWeight={600}>
              <x.span color="gray" fontWeight={400}>&copy; {new Date().getFullYear()} </x.span>
              <FormattedMessage id="footer.copyright" defaultMessage="ROBE lighting s.r.o." />
            </x.p>
          </x.div>

          <x.p color="gray" mt={{ _: "0.7rem", sm: 0 }} style={{ userSelect: "none" }}>
            <x.span onClick={() => setClicks(clicks + 1)} cursor="pointer">All rights reserved. Created by{" "}</x.span>

            <TextLink
              href="https://appio.dev/"
              target="_blank"
              color={{ _: "inherit", hover: "#fff" } as any}
              borderColor="gray"
              lineHeight="initial"
              fontSize="inherit"
              fontWeight="inherit"
              v="underlined"
            >
              Appio
            </TextLink>
          </x.p>
        </x.div>
      </Container>
    </FooterStyle>
  );
};

export default memo(Footer);

const content = defineMessages({
  title: {
    id: "footer.title3",
    defaultMessage: "Stay in touch"
  },
  about: {
    id: "footer.aboutUs",
    defaultMessage: "About Robe Lighting"
  },
  contacts: {
    id: "footer.contacts",
    defaultMessage: "Contacts"
  },
  privacy: {
    id: "footer.privacy",
    defaultMessage: "Privacy"
  },
  terms: {
    id: "footer.terms",
    defaultMessage: "Terms and Conditions"
  },
  termsCze: {
    id: "footer.termsCze",
    defaultMessage: "Terms and Conditions (CZE)"
  },
  complaint: {
    id: "footer.complaint",
    defaultMessage: "Complaint Policy"
  },
  complaintCze: {
    id: "footer.complaintCze",
    defaultMessage: "Complaint Policy (CZE)"
  }
});

const FooterStyle = styled(x.div)`
  background: #000;

  @media (min-width: 48em) {
    background: #000 url("/_next/image?url=/static/img/footer/foot-1024.jpg&w=1024&q=75");
  }
  
  @media (min-width: 64em) {
    background: #000 url("/_next/image?url=/static/img/footer/foot-1440.jpg&w=1440&q=75");
  }
  
  @media (min-width: 90em) {
    background: #000 url("/_next/image?url=/static/img/footer/foot-1920.jpg&w=1920&q=75");
  }

  @media (min-width: 120em) {
    background: #000 url("/_next/image?url=/static/img/footer/foot-2560.jpg&w=2560&q=75");
  }
`;
