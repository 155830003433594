import { FC, MouseEventHandler, useEffect, useState } from "react";
import { TextLink, TextLinkProps } from "anolis-ui";
import { x } from "@xstyled/emotion";
import Link from "next/link";
import { useRouter } from "next/router";
import { SystemProps } from "@xstyled/system";

interface NavLinkProps extends TextLinkProps {
  onMouseEnter?: MouseEventHandler<HTMLDivElement | HTMLAnchorElement>;
  onMouseLeave?: MouseEventHandler<HTMLDivElement | HTMLAnchorElement>;
  active?: boolean;
  categorySlug: string;
  name: string;
}

const NavLink: FC<NavLinkProps> = (
  {
    children,
    active,
    categorySlug,
    name,
    ...props
  }) => {
  const { query: { slug } } = useRouter();
  const [current, setCurrent] = useState(false);

  useEffect(() => {
    setCurrent(categorySlug.toLowerCase() === slug?.toString().toLowerCase());
  }, [categorySlug, slug]);

  return (
    <x.div
      data-group
      bg={active ? "black" : { hover: "black" }}
      transition="background 300ms"
      {...props as SystemProps}
    >
      <Link href={`/category/${categorySlug.toLowerCase()}`} passHref>
        <TextLink
          fontWeight="700"
          textTransform="uppercase"
          color={active ? "#fff" : current ? "robe" : { _: "black", hover: "#fff" }}
          h="100%"
          display="flex"
          alignItems="center"
          px={{ _: "1.5rem", xl: "2rem" }}
        >
          {name}
        </TextLink>
      </Link>
    </x.div>
  );
};

export default NavLink;
