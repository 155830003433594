import { x } from "@xstyled/emotion";
import { FC, Fragment, ReactElement, ReactNode } from "react";
import { useField, useFormikContext } from "formik";
import { DropMenu, DropMenuButton, DropMenuItem, DropMenuList, Icon, Input, InputProps } from "anolis-ui";
import { SystemProps } from "@xstyled/system";
import DropIcon from "components/icons/16/drop.svg";
import Label from "components/formik/Label";
import Loading from "components/ui/Loader/Loading";

export interface SelectOption {
  name: string;
  value: string;
}

export interface SelectFieldProps extends SystemProps {
  name: string;
  labelText?: string | ReactNode;
  label?: ReactElement;
  _labelText?: SystemProps;
  inputProps?: InputProps;
  required?: boolean;
  options?: SelectOption[];
  disabled?: boolean;
  onChange?: (val: string) => void;
}

const SelectField: FC<SelectFieldProps> = ({ onChange, options, ...props }) => {
  const [field, meta] = useField(props.name);
  const form = useFormikContext<any>();
  const isError = meta.touched && meta.error !== undefined;

  const getOptionName = (value: string): string => {
    const option = options?.find(o => o.value === `${value}`);
    return option ? option.name : "";
  };

  const dropSelect = (option: { value: string; name: string }) => {
    form.setFieldValue(field.name, option.value);
    if (onChange) onChange(option.value);
  };

  return (
    <Label {...props}>
      <x.div
        fontFamily="avenir"
        fontWeight={600}
        fontSize="0.875rem"
        lineHeight="1.3125"
        mb="0.25rem"
        {...props._labelText}
        {...isError && { color: "robe" }}
      >
        {props.label}
        {props.labelText &&
        <Fragment>{props.labelText} {props.required && <x.span color="robe" ml="0.25rem">*</x.span>}</Fragment>}
      </x.div>

      <DropMenu>
        {({ isExpanded }: { isExpanded: boolean }) => (
          <Fragment>
            <DropMenuButton
              w="100%"
              customButton={(
                <Fragment>
                  <Input
                    pt="0.6875rem"
                    pb="0.6875rem"
                    rightIcon={(
                      <Icon
                        svg={<DropIcon />}
                        transform={isExpanded && !props.disabled ? "rotate(180deg)" : "rotate(0)"}
                        transition="transform 300ms"
                      />
                    )}
                    pointerEvents="none"
                    {...props.inputProps}
                    value={getOptionName(props.inputProps?.value ?? field.value)}
                    _input={{
                      placeholder: "Select...",
                      ...props.inputProps?._input
                    }}
                  />
                </Fragment>
              )}
            />

            {!props.disabled && (
              <DropMenuList position="exactWidth">
                {isExpanded && options ? options!.map((option, i) => (
                  <DropMenuItem
                    onSelect={() => dropSelect(option)}
                    key={i}
                  >
                    {option.name}
                  </DropMenuItem>
                )) : <Loading />}
              </DropMenuList>
            )}
          </Fragment>
        )}
      </DropMenu>
    </Label>
  );
};

export default SelectField;
