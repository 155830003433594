import { ApiUserLoginRequest } from "@ecommerce/core/generated";
import { ResponseError } from "@ecommerce/core/src";
import { x } from "@xstyled/emotion";
import { Button, TextLink, Txt, useModal } from "anolis-ui";
import { useUser } from "components/context/AppContext/contexts";
import FormStatus from "components/formik/FormStatus";
import mapErrors from "components/formik/mapErrors";
import PasswordField from "components/formik/PasswordField";
import SubmitButton from "components/formik/SubmitButton";
import TextField from "components/formik/TextField";
import { formLocale } from "components/forms/formLocale";
import LostPasswordModal from "components/modals/LostPassword";
import CreateAccountBeforeOrder from "components/ui/CreateAccountBeforeOrder";
import { Form, Formik, FormikHelpers } from "formik";
import { Dispatch, FC, Fragment, ReactElement, SetStateAction, useState } from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { object, string } from "yup";

interface Props {
  setNavOpen?: Dispatch<SetStateAction<boolean>>;
}

const loginSchema = object().shape({
  username: string().required({ ...formLocale.textRequired }),
  password: string().required({ ...formLocale.textRequired })
});

const preloginSchema = object().shape({
  username: string().required({ ...formLocale.textRequired })
});

const LoginForm: FC<Props> = ({ setNavOpen }): ReactElement => {
  const { formatMessage } = useIntl();
  const { login, prelogin } = useUser();
  const [close] = useModal();
  const [openModal] = useModal(LostPasswordModal);
  const [emailExists, setEmailExists] = useState<boolean>();
  const [email, setEmail] = useState<string>();
  const [successLogin, setSuccessLogin] = useState<boolean>(false);
  const [isEmptyPassword, setIsEmptyPassword] = useState<boolean>(false);

  const handleLogin = async (
    { username, password }: ApiUserLoginRequest,
    helpers: FormikHelpers<ApiUserLoginRequest>
  ) => {
    const handleErrors = (error: any) => {
      if (error.errors.username === "NotFound") {
        helpers.setStatus({ type: "error", message: formatMessage(content.userNotFound) });
      }
      if (error.errors.password === "Empty") {
        setIsEmptyPassword(true);
        helpers.setStatus(undefined);
      }
      if (error.errors.verification === "EmailNotVerified") {
        helpers.setStatus({ type: "error", message: formatMessage(content.notVerified) });
      } else if (error.errors?.password) {
        helpers.setStatus({ type: "error", message: formatMessage(content.wrongPassword) });
      }
    };

    if (!emailExists) {
      await prelogin(username).then(res => {
        setEmailExists(true);
        helpers.setStatus(undefined);
        setEmail(username);
      }).catch(handleErrors);
      return;
    }
    helpers.setSubmitting(true);
    await login(username, password)
      .then(async (user) => {
        if (user.customer?.id) {
          helpers.setSubmitting(false);
          helpers.resetForm();
          setNavOpen?.(false);
          setSuccessLogin(true);
          setTimeout(() => close(), 3500);
        } else {
          mapErrors(new ResponseError(400, "No customer", {}), helpers);
        }
      })
      .catch(handleErrors);
  };

  const initialValues: ApiUserLoginRequest = {
    username: "",
    password: ""
  };

  return (
    <Fragment>
      <Formik
        initialValues={initialValues}
        onSubmit={handleLogin}
        validationSchema={emailExists ? loginSchema : preloginSchema}
        validateOnBlur={!emailExists}
      >
        {!successLogin ? (
          <Form>
            <FormStatus />
            <TextField
              name="username"
              labelText={formatMessage(content.email)}
              required
              autofocus
              inputProps={{
                _input: {
                  type: "email",
                  onChange: () => setEmailExists(undefined)
                }
              }}
            />
            {emailExists && (
              <x.div mt="0.75rem">
                <PasswordField
                  name="password"
                  autofocus
                  label={(
                    <x.div display="flex" justifyContent="space-between">
                      <x.span>
                        <FormattedMessage id="components.dialog.login.password" defaultMessage="Password" />
                      </x.span>

                      <TextLink
                        v="underlined"
                        fontSize="inherit"
                        lineHeight="inherit"
                        fontWeight={400}
                        onClick={() => {
                          close();
                          openModal({ username: email ?? "" });
                        }}
                      >
                        {formatMessage(content.message)}
                      </TextLink>
                    </x.div>
                  )}
                />
              </x.div>
            )}

            {isEmptyPassword && (
              <x.div>
                <x.div mt="1rem">
                  <Txt t="h6" mt="1rem" lineHeight="1.5rem">
                    <FormattedMessage
                      id="components.dialog.login.settingPassword.text"
                      defaultMessage="You are one step away from shining with us!
                      We have sent you a link to the given e-mail where you can set your password."
                    />
                    <br />
                    <br />
                    <FormattedMessage
                      id="components.dialog.login.settingPassword.noEmail"
                      defaultMessage="If you have not received an e-mail, please try again later or"
                    />{" "}
                    <TextLink v="underlined" href="/static/contacts" target="_blank">
                      {formatMessage(content.link)}
                    </TextLink>.
                  </Txt>
                </x.div>
              </x.div>
            )}

            <x.div mt="1.5rem">
              {isEmptyPassword ? (
                <x.div mt="1.5rem" display="flex" justifyContent="center">
                  <Button onClick={close}>
                    <FormattedMessage id="components.dialog.login.settingPassword.button" defaultMessage="Got it" />
                  </Button>
                </x.div>
              ) : (
                <SubmitButton>
                  {formatMessage(content.button)}
                </SubmitButton>
              )}
            </x.div>
          </Form>
        ) : (
          <x.p style={{ textAlign: "center" }} fontSize="1.15rem">
            <FormattedMessage id="components.dialog.login.success" defaultMessage="You are now successfully logged in." />
          </x.p>
        )}
      </Formik>
      {!emailExists && <CreateAccountBeforeOrder />}
    </Fragment>
  );
};

export default LoginForm;

const content = defineMessages({
  email: {
    id: "components.dialog.login.email",
    defaultMessage: "E-mail address"
  },
  button: {
    id: "components.dialog.login.button",
    defaultMessage: "Login"
  },
  link: {
    id: "components.dialog.login.contactLink",
    defaultMessage: "contact us"
  },
  message: {
    id: "components.dialog.login.forgottenPassword",
    defaultMessage: "Forgot your password?"
  },
  userNotFound: {
    id: "components.dialog.login.userNotFound",
    defaultMessage: "Username was not found."
  },
  wrongPassword: {
    id: "components.dialog.login.wrongPassword",
    defaultMessage: "Password is incorrect."
  },
  notVerified: {
    id: "components.dialog.login.notVerified",
    defaultMessage: "E-mail is not yet verified."
  }
});
