import { FC } from "react";
import ProductCard, { ProductCardProps } from "components/cards/ProductCard";
import Link from "next/link";
import { x } from "@xstyled/emotion";
import { TextLink } from "anolis-ui";
import { FormattedMessage } from "react-intl";
import { ApiCategory } from "@ecommerce/core/generated";
import CategoryCard from "components/cards/CategoryCard";
interface SearchDropdownProps {
  term: string;
  products: ProductCardProps[];
  category?: ApiCategory;
  categoryPath?: string[];
}

const SearchDropdown: FC<SearchDropdownProps> = ({ term, products, category, categoryPath }) => {
  return (
    <x.div
      position="absolute"
      top="100%"
      w="100%"
      bg="#fff"
      pb={products.length > 8 || !products.length ? "1.5rem" : "0"}
      boxShadow="0 0.5rem 1rem 0 rgba(30, 30, 30, 0.1), 0 1rem 2rem 0 rgba(30, 30, 30, 0.1)"
      left="50%"
      transform="translateX(-50%)"
      maxW="40rem"
      display={products === undefined ? "none" : "block"}
    >
      {category && categoryPath && (
        <CategoryCard
          name={category.name}
          slug={category.slug}
          path={categoryPath}
        />
      )}

      {products.slice(0, 8).map((product) => (
        <ProductCard
          {...product}
          categories={product.categories}
          categoriesInline
          direction="row"
          key={product.id}
        />
      ))}

      <x.div display="flex" justifyContent="center" mt="1rem">
        {products && !products.length
          ? (
            <x.p>
              <FormattedMessage
                id="components.topBar.searchDropdown.noResults"
                defaultMessage="Could not find any results"
              />
            </x.p>
          )
          : products.length > 8 && (
            <Link
              href={{
                pathname: "/search",
                query: { term }
              }}
              passHref
            >
              <TextLink
                v="underlined"
                fontSize="1.5rem"
                fontWeight={600}
                lineHeight="2rem"
                color={{ _: "gray", hover: "black" }}
                borderColor="gray"
              >
                <FormattedMessage id="components.topBar.searchDropdown.showResults" defaultMessage="Show all" />
              </TextLink>
            </Link>
          )}
      </x.div>
    </x.div>
  );
};

export default SearchDropdown;
